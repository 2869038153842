import React, { useState } from "react";
import axios from "axios";
import { Ring } from "react-awesome-spinners";
import { useForm } from "react-hook-form";

import addToMailchimp from "gatsby-plugin-mailchimp";

import TextError from "../../components/notifications/TextError";

import Success from "../../components/notifications/SuccessMessage";
import Error from "../../components/notifications/Error";
import Layout from "../../components/layout/layout";

import SEO from "../../components/seo";

const MailChimpForm = () => {
  const [errorText, setErrorText] = useState("");
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState("");
  const {
    register,
    handleSubmit,
    watch,

    formState: { errors, isDirty, isSubmitted, isSubmitting },
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: {
      honey: "",
      email: "",
    },
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
  });

  // if (errors) {
  //   console.log("errors: ", errors);
  // }

  const onSubmit = async data => {
    if (data.honey !== "") {
      setErrorText("Spam suspected");
      return;
    }
    delete data.honey;

    try {
      setErrorText("");
      setLoading(true);
      console.log("submitted data: ", data);
      const res = await addToMailchimp(data.email);
      // const res = await axios.post(airtableURL, data);
      console.log("mailchimp res", res);
      // if (res.data.statusCode === 400) {
      //   setErrorText(res.data.message);
      //   setLoading(false);
      // } else if (res.data.statusCode === 200) {
      setResult("Submitted successfully");
      setLoading(false);
      // }
    } catch (err) {
      // setErrorText(err.message);
      // setLoading(false);
    }
  };

  return (
    <>
      <div className="absolute w-full h-32 z-n10 bg-gradient-to-r from-swa-1 to-swa-5"></div>
      <Layout>
        <SEO title="Email sign up " />

        <div className="mx-3 mb-12 space-y-8 divide-y divide-gray-200 md:mx-auto md:max-w-xl lg:max-w-2xl">
          <div className="px-10 py-5 ">
            <h1 className="text-swa-3">Sign up for SWA Email Updates</h1>
            <p className="max-w-2xl mt-1 text-gray-500">
              Please provide your valid email address to get event updates from
              SWA
            </p>
            {errorText && <Error msg={errorText} />}
            {isSubmitted && result && (
              <Success msg={result}>Thank you for signing up</Success>
            )}
            {!isSubmitted && errorText === "" && result === "" && (
              <form
                className="max-w-xl mt-5 space-y-5"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                  <div className="pt-4 space-y-6 sm:pt-4 sm:space-y-5">
                    <div>
                      <h3 className="text-lg font-bold leading-6 text-swa-1">
                        Your email address
                      </h3>
                    </div>
                    <div className="space-y-6 sm:space-y-5">
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label
                          htmlFor="email"
                          className={
                            errors.email
                              ? `block font-medium text-red-500 sm:mt-px sm:pt-2 `
                              : `block font-medium text-gray-700 sm:mt-px sm:pt-2`
                          }
                        >
                          Email address
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <input
                            {...register("email", {
                              required: true,
                              maxLength: 100,
                              pattern:
                                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            })}
                            id="email"
                            name="email"
                            type="email"
                            autoComplete="email"
                            className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm focus:ring-swa-3 focus:border-swa-3 sm:"
                          />
                          {errors.email?.type === "required" && (
                            <p className="text-sm text-red-500 ">
                              Email address is required
                            </p>
                          )}
                          {errors.email?.type === "maxLength" && (
                            <span className="text-sm text-red-500">
                              You have exceeded maximum length of 100 characters
                            </span>
                          )}
                          {errors.email?.type === "pattern" && (
                            <span className="text-sm text-red-500">
                              Please enter a valid email format
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pt-5">
                  <div className="flex justify-end">
                    {!isSubmitting && (
                      <button
                        type="submit"
                        disabled={!isDirty || errors.email}
                        className="inline-flex justify-center px-4 py-2 ml-3 font-medium text-white border border-transparent rounded-md shadow-sm bg-swa-1 hover:bg-swa-3 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-swa-3 disabled:opacity-30"
                      >
                        Submit
                      </button>
                    )}
                    {loading && <Ring color="rgb(112,48,140)" />}
                    {errorText !== "" && <TextError>{errorText}</TextError>}
                  </div>
                </div>
              </form>
            )}
          </div>
          <iframe
            width="640px"
            height="480px"
            src="https://forms.office.com/Pages/ResponsePage.aspx?id=LldTO-My3kiR_RDMZhsmk1aQJsU-DiVCkgvds6Nz4ZxUQlc5R0lCOEJFVkpCTzlSMUNORUFYRThHNi4u&embed=true"
            frameBorder="0"
            marginWidth="0"
            marginHeight="0"
            style={{ border: "none", maxWidth: "100%", maxHeight: "100vh" }}
            allowFullScreen
            webkitAllowFullScreen
            mozAllowFullScreen
            msAllowFullScreen
          ></iframe>
        </div>
      </Layout>
    </>
  );
};

export default MailChimpForm;
